.modal-backdrop {
    opacity: .5;
}

.modal-header,
.alert {
    .close {
        padding: 0.5rem 1.75rem;
        background: transparent;
        border: 0;
        font-size: 25px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: .5;
        width: 1em;
        height: 1em;
        z-index: 2;

        button {
            display: none;
        }
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    height: $input-height !important;
    line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}


.dataTables_length {
    select {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.datepicker__input {
    background-color: $input-bg;
    height: $input-height !important;
    line-height: 2.5 !important;
}

.vnis__input, .datepicker__wrapper, .datepicker__inner, .datepicker__input {
    background-color: $input-bg !important;
    color: $input-color !important;
    border-color: $input-border-color !important;
}

.datepicker__dummy-wrapper {
    overflow: hidden;
    border-color: $input-border-color !important;
}

.datepicker__input:focus {
    outline: 0px solid !important;
}

// .datepicker__week-row {
//     border-bottom: 5px solid $hr-border-color !important;
// }
// .datepicker__months:before {
//     background: $hr-border-color !important;
// }
.datepicker__month-day--allowed-checkout:hover, .datepicker__month-day--valid:hover {
    background-color: $primary !important;
    color: $white !important;
}

.mx-datepicker {
    .mx-icon-calendar {
        color: $input-color !important;
    }
}

.blog-card {
    > h5 {
        position: absolute;
        margin-top: 10px !important;
    }

    .tabs {
        width: 100%;

        .card-body {
            padding: 1.25rem 0 0;
        }
    }
}


.modal-backdrop {
    opacity: .5;
}

.modal-header,
.alert {
    .close {
        padding: 0.5rem 1.75rem;
        background: transparent;
        border: 0;
        font-size: 25px;
        position: absolute;
        top: -3px;
        right: 0;
        opacity: .5;
        width: 1em;
        height: 1em;
        z-index: 2;

        button {
            display: none;
        }
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    height: $input-height !important;
    line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}

.wh-64 {
    width: 64px !important;
    height: 64px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mx-datepicker {
    width: 149px !important;
}

.body {
    background: #efefef !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.background__login {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
}


[tooltip] {
    position: relative;
}

[tooltip]::before,
[tooltip]::after {
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;

    /* opinions */
    text-transform: none;
    font-size: .9em;
}

[tooltip]::before {
    content: '';
    z-index: 1001;
    border: 5px solid transparent;
}

[tooltip]::after {
    content: attr(tooltip); /* magic! */
    z-index: 1000;

    /* most of the rest of this is opinion */
    font-family: Helvetica, sans-serif;
    text-align: center;

    /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* visible design of the tooltip bubbles */
    padding: 1ch 1.5ch;
    border-radius: .3ch;
    box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
    display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
    display: none !important;
}

[tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
}

[tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, .5em);
}

@keyframes tooltips-vert {
    to {
        opacity: .9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: .9;
        transform: translate(0, -50%);
    }
}

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}


.multiselect__content-wrapper{
    z-index: 9999;
}

.forest__entry-table {
    tr {
        td:first-child {
            width: 20%;
        }
    }

    .forestSubTitle {
        text-align: right;
    }
}

.table-head, .table-foot {
    background: #2460b9;
    color: #fff;
}
